import { reactive, computed } from 'vue';
import { json2csv } from 'csvjson-json2csv';

/**
 * @typedef {Object} Offer
 * @property {Number} id - Unique identifier
 * @property {String} name - Offer name
 * @property {Config[]} configs - Configurators linked to the offer
 * @property {Axi[]} axis - Axis shared by all configurators of the offer
 */

/**
 * @typedef {Object} ConfigOffer - A simplified version of an offer for a given configurator
 * @property {Number} id - Unique identifier
 * @property {String} name - Offer name
 * @property {Config} config - Configurator for which the offer has been simplified
 * @property {Axi[]} axis - Axis relevant for the given configurator
 */

/**
 * @typedef {Object} Config - Configurator
 * @property {Number} id - Unique identifier
 * @property {String} name - Config name
 * @property {String} threekit - Threekit uuid
 * @property {String} plm - PLM name
 * @property {Number[]} [excludedAxis] - Array of axis ids that does not apply to this configurator
 */

/**
 * @typedef {Object} Axi - Axi
 * @property {Number} id - Unique identifier
 * @property {String} name - Axi name
 * @property {String} [threekit] - Threekit name if any
 * @property {String} [jde] - JDE name if any
 * @property {String} [plm] - PLM name if any
 * @property {("choice"|"computed"|"text")} type - Axi type
 * @property {Value[]} [values] - If type = choice or computed, array of values available in this axis
 * @property {Object.<string|number, Value>} [valuesById] - In the context of a ConfigOffer, a dictionary of values by id
 * @property {Rule[]} [rules] - Rules that apply to this axi
 */

/**
 * @typedef {Object} Value - Value
 * @property {Number} id - Unique identifier
 * @property {String} name - Value name
 * @property {String} [threekit] - Threekit name if any
 * @property {String} [jde] - JDE name if any
 * @property {String} [plm] - PLM name if any
 */

/**
 * @typedef {Object} Rule - Rule
 * @property {Number} id - Unique identifier
 * @property {String} name - Rule name
 * @property {Number[]} [configs] - IDs of the configurators that are affected by this rule
 * @property {Number[]} [conditions] - IDs of the values that are required to apply this rule // TODO: assume IDs are unique; TBC
 * @property {Constraint[]} [constraints] - A ready to use version of the conditions property ; available only in a ConfigOffer context // TODO : implement it as a computed?
 * @property {("include"|"exclude")} [type] - Rule type (include or exclude); apply to choice axis only
 * @property {Number[]} [values] - IDs of the values that are applied by this rule; apply to choice axis only
 * @property {Number} [value] - ID of the value that is applied by this rule; apply to computed axis only
 * @property {("show"|"hide")} [visibility] - Indicate if the rule should make the text input visible or not; apply to text axis only
 * @property {String} [start] - Start date of the rule // TODO: careful with the timezone
 * @property {String} [end] - End date of the rule // TODO: careful with the timezone
 */

/**
 * @typedef {Object} Constraint - Constraint
 * @property {Axi} axi - Axi
 * @property {Number[]} conditions - IDs of the values that are required to apply the rule
 * /

/**
 * @typedef {Object} Configuration - Available options for an axi given the current configuration
 * @property {Axi} axi - Axi
 * @property {Value[]} values - Values that can be selected for this axi
 * @property {Value} value - Selected value
 * @property {("show"|"hide")} visibility - Indicate if the text input should be visible or not
 */

/**
 * @typedef {Object} IndexOfRules - The index of rules to quickly find the applicable rules for a given axi
 * @property {IndexedRule[]} rules - Rules that apply to the axi
 * @property {Object.<string|number, IndexOfRules>} [constrained] - Constraints for each value of the axi
 * @property {IndexOfRules} [unconstrained] - Constraints for the axi if it is not constrained
 */

/**
 * @typedef {Object} IndexedRule - Indexed rule
 * @property {Number} order - Order of the rule (to rebuild the rule set with the same order as original)
 * @property {Rule} rule - Rule itself
 */

function cartesianProduct(arr) {
  return arr.reduce(function (a, b) {
    return a.map(function (x) {
      return b.map(function (y) {
        return x.concat([y]);
      })
    }).reduce(function (a, b) { return a.concat(b) }, [])
  }, [[]])
}

const state = reactive({
  /** @type {[Offer]} */
  offers: JSON.parse(localStorage.getItem('offers') || "[]"),
});

const getters = {

  itemsDic: computed(() => {
    return state.offers.reduce((acc, offer) => {
      acc[offer.id] = offer
      if (offer.configs) {
        offer.configs.forEach(config => {
          acc[config.id] = { ...config, offerId: offer.id }
        })
      }
      if (offer.axis) {
        offer.axis.forEach(axi => {
          acc[axi.id] = { ...axi, offerId: offer.id }
          if (axi.values) {
            axi.values.forEach(value => {
              acc[value.id] = { ...value, axiId: offer.id }
            })
          }
        })
      }
      return acc
    }, {})
  }),

}

const methods = {

  // HELPERS

  /**
   * Create an id that does not already exists in itemsDic
   * @returns {Number} - A unique identifier
   */
  createId() {
    let id;
    do {
      id = Math.floor(Math.random() * 1000000)
    } while (getters.itemsDic[id])
    return id;
  },
  getId: (array, id) => array?.find((item) => item.id === id),

  /**
   * Download a JSON object as a CSV file
   * @param {*} json - The JSON object to download
   * @param {*} filename - The name of the file
   */
  downloadJson2Csv(json, filename, headers) {
    let finalJson = json;
    if (headers) finalJson = [headers.reduce((acc, h) => ({ ...acc, [h]: "-" }), {}), ...json]; // We add a line of dashes with the correct headers
    let csv = json2csv(finalJson, { separator: ';' });
    if (headers) csv = csv.replace(/(.*?)\n.*?\n/, '$1\n'); // We remove the second line, the one with the dashed
    const blob = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'text/csv' }); // Add BOM to force Excel to open the file with the correct encoding
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = `${filename}-${new Date().toISOString().slice(0, 10)}.csv`;
    link.click();
    URL.revokeObjectURL(url);
  },

  // SAVE, DOWNLOAD & UPLOAD

  save() {
    const json = JSON.stringify(state.offers)
    localStorage.setItem("offers", json)
  },
  download() {
    const json = JSON.stringify(state.offers)
    const blob = new Blob([json], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = `ttop-tool-backup-${new Date().toISOString().slice(0, 10)}.json`;
    link.click();
    URL.revokeObjectURL(url);
  },
  upload() {
    const input = document.createElement('input')
    input.type = 'file'
    input.accept = 'application/json'
    input.onchange = (event) => {
      const file = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        const contents = e.target.result
        state.offers = JSON.parse(contents)
      }
      reader.readAsText(file)
    }
    input.click()
  },

  // TTOP GENERATION

  /**
   * Return the axis compatible with a given configurator
   * @param {Offer} offer 
   * @param {Config} config 
   * @returns {Axi[]}
   */
  getConfigAxis(offer, config) {
    return offer.axis.filter(axi => !config.excludedAxis?.includes(axi.id))
  },

  /**
   * Return the offer but minimized to only keep axis, values and rules relevant for the given configurator. Can add a SKU axi if provided
   * @param {Offer} offer
   * @param {Config} config
   * @param {Axi} skuAxi - The SKU axi if any
   * @param {Date | undefined} targetDate - If provided, only the rules that are applicable at this date are kept
   * @returns {ConfigOffer}
   */
  getConfigOffer(offer, config, skuAxi, targetDate) {
    // We create a copy of the offer to avoid modifying the original object
    const offerCopy = JSON.parse(JSON.stringify(offer));
    // If provided, we add the SKU axi
    if (skuAxi) offerCopy.axis.push(skuAxi);
    // We make a snapshot of the axis values before the optimization
    const axisValues = offerCopy.axis.reduce((acc, axi) => {
      acc[axi.id] = axi.values.map(val => val.id)
      return acc
    }, {})
    // We keep only the axis that are relevant for the configurator
    const configAxis = this.getConfigAxis(offerCopy, config);
    // We simplify the remaining axis
    for (let axi of configAxis) {
      // We clean the rules to only keep values that correspond to existing values of the axi (to correct eventual mistakes)
      // TODO : improve the fiability of the tool so such a cleaning is not necessary
      axi.rules = axi.rules.reduce((acc, rule) => {
        if (skuAxi && axi.id === skuAxi.id) {
          acc.push(rule); // Bypass the verification for the SKU axi (it can have many rules and they are not really "optimizable")
        }
        else if (axi.type === "choice") {
          const values = rule.values.filter(valId => axi.values.map(v => v.id).includes(valId));
          // We add back the rule only if it still have values, or if it had no values (meaning "all values") to begin with
          if (values?.length || !rule.values?.length) acc.push({ ...rule, values });
          if (values?.length !== rule.values?.length) console.log(`The rule ${rule.name} of ${axi.name} had values that did not correspond to the axi values`);
        }
        else if (axi.type === "computed") {
          const value = axi.values.map(v => v.id).includes(rule.value) ? rule.value : null;
          if (value) acc.push({ ...rule, value });
          if (value !== rule.value) console.log(`The rule ${rule.name} of ${axi.name} had a value that did not correspond to the axi values`);
        }
        else {
         acc.push(rule)
        }
        return acc;
      }, []);
      // If the axi has no rules, we create a rule for the default behavior (and we filter away the undefined rules)
      // TODO: instead of adding the defaultRule only for axi without rules, it should be added always (implies a modification of the UX)
      if (!axi.rules?.length) axi.rules = [this.createDefaultRule(axi)].filter(Boolean)
      // We keep only the rules that are relevant for the configurator
      axi.rules = axi.rules.filter(rule => rule.configs.length === 0 || rule.configs.includes(config.id))
      // We keep only the rules that are applicable at the target date
      if (targetDate) axi.rules = axi.rules.filter(rule => (!rule.start || (new Date(rule.start) <= targetDate)) && (!rule.end || (new Date(rule.end) >= targetDate))).map(rule => ({ ...rule, start: undefined, end: undefined }))
      // For each rule we create the "constraints" property which organize the conditions by axi
      for (let rule of axi.rules) {
        rule.constraints = offerCopy.axis
          // For each axi of the offer, we create an object with conditions that apply on this axi
          // constraints: [{ axi: Axi1, conditions: [cond1.1, cond1.2] }, { axi: Axi2, ... }, ... ]
          .map(_axi => ({ axi: _axi, conditions: axisValues[_axi.id].filter(valueId => rule.conditions.includes(valueId)) }))
          // We keep only the constraints that have at least one condition
          // (if the conditions set is empty, it means that the rule applies to all the values of the axi, so we remove the constraint on this axi)
          .filter(_constr => _constr.conditions.length > 0)
          // We keep only the constraints that apply to axis before the current axi
          // If "axi" is the 5th axis, we keep only the constraints that apply to the 1st, 2nd, 3rd and 4th axis
          // Normally, the UX should prevent the user to create constraints on axis that are after the current one, but we do it just in case
          // TODO: add an error in our error list if one conditions were on a "after" axi 
          .filter(_constr => offerCopy.axis.findIndex(_axi => _axi.id === _constr.axi.id) < offerCopy.axis.findIndex(_axi => _axi.id === axi.id))
          // We keep only the constraints that have at least one of the potential values of the axi not included in the constraint conditions 
          // (if the constraint cond is "must be green, blue or red" and the potential values for the corresponding axi are only "green or blue", the constraint is useless since it will always be true)
          // (for the constraint to be useful, at least one potential value must not be in the conditions ; for example "green, blue or yellow")
          .filter(_constr => _constr.axi.values.map(val => val.id).some(valId => !_constr.conditions.includes(valId)))
          // We remove from the conditions of the constrainst the values that are not among the potential values of the corresponding axi
          // (for example, if the conditions are [green, blue, yellow] and the potential values are [green, blue], we keep only [green, blue])
          .map(_constr => ({ axi: _constr.axi, conditions: _constr.conditions.filter(cond => _constr.axi.values.map(val => val.id).includes(cond)) }))
        // We update the conditions array to be in sync with the constraints
        // (the constraints are built from the conditions of the rule, but now that we have simplified the constraints, we can update the rules conditions to be in sync)
        rule.conditions = rule.constraints.flatMap(constr => constr.conditions)
      }
      // We remove the rules that are constrained by axis that are not part of the configAxis (and are therefore never applicable)
      // (if a constraints requires that "opening side" is "right", but the product does not have an "opening side" axi, the rule is useless)
      axi.rules = axi.rules.filter(rule => !rule.constraints.some(constraint => !configAxis.map(_axi => _axi.id).includes(constraint.axi.id)));
      // We remove the rules that have constraints that do not have any remaining conditions and so will never be applicable
      // (because those conditions were not part of the potential values of the axi and therefore were removed)
      // (for example, the constraint conditions were "must be green or blue" but the potential values of the axi were only "red or yellow", so the conditions were simplified to [])
      axi.rules = axi.rules.filter(rule => !rule.constraints.some(constraint => constraint.conditions.length === 0));
      // We now transform the "complex" rules (IF [axi1 equal X1 OR Y1] AND [axi2 equals X2 OR Y2] include V1, V2, V3, V4) 
      // in a lot of ultra simple rules (IF axi1 = X1 AND axi2 = X2 include V1 ; IF axi1 = X1 AND axi2 = X2 include V2 ; etc.)
      // This will help to optimize the rules in the next step
      const decomposedRules = [];
      for (let rule of axi.rules) {
        // We prepare an array of arrays of values that will be cross multiplied
        const valuesToCrossMultiply = [
          // In case it is a "choice" axi, we include the rule.values or all the values of the axi if no values are specified
          axi.type === "choice" ? rule.values.length ? rule.values : axi.values.map(val => val.id) : [null],
          // Finally, we Converts the constraints of form [{ axi: Axi1, conditions: [cond1.1, cond1.2] }, { axi: Axi2, ... }, ... ] 
          // into [[{ axi: Axi1, conditions: [cond1.1] }, { axi: Axi1, conditions: [cond1.2] }], [{ axi: Axi2, ... }, ...]]
          ...rule.constraints.map(constr => constr.conditions.map(cond => ({ axi: constr.axi, conditions: [cond] })))
        ]
        // We do the cartesian product of the values
        let combinations = cartesianProduct(valuesToCrossMultiply);
        // We flatten the rules
        combinations.forEach(([values, ...constraints]) => {
          decomposedRules.push({ ...rule, values: [values], constraints, conditions: [...constraints.flatMap(constr => constr.conditions)] })
        })
      }
      // We replace the rules by the decomposed one
      axi.rules = decomposedRules;
      // If we are in the skuAxi, we skip the following optimizations (there can be many rules so it can be slow, and they cannot really be "optimized")
      if (!skuAxi || axi.id !== skuAxi.id) {
        // We now optimize the rules by removing the ones that will be overriden by a following one
        // For each rule, starting from the last, we go up the list and remove the rule if there are less restrictive rules below
        for (let i = axi.rules.length - 1; i >= 0; i--) {
          const rule = axi.rules[i];
          // Get the list of rules below the current one
          let belowRules = axi.rules.slice(i + 1)
          // If the axi is a choice, we filter to look only at the rules that apply on the same values
          // Note: at this step, there should be only one value in the rule (if not, the simplification algo will "work", but probably not have any effect)
          if (axi.type === "choice") {
            belowRules = belowRules.filter(belowRule => rule.values.every(val => belowRule.values.includes(val)))
          }
          // We remove the current rule if there is a less restrictive one below
          if (belowRules.some(belowRule => this.isEquallyOrLessRestrictive(belowRule, rule))) {
            console.log(`The rule ${rule.name} of ${axi.name} does not apply because there is a less restrictive rule below that will override it`);
            axi.rules.splice(i, 1)
          }
        }
      }
      //  Now that the rules are almost completely optimized, we can compute what are the values that are reachable for the axi
      let reachableValuesId = new Set();
      for (let i = 0; i < axi.rules.length; i++) {
        const rule = axi.rules[i];
        // If the axi is a choice
        if (axi.type === "choice") {
          // We consider "reachables" the values that are included by at least one rule
          if (rule.type === "include") {
            const ruleValues = rule.values.length ? rule.values : axi.values.map(val => val.id)
            reachableValuesId.add(...ruleValues);
          }
          // We optimize the exclude rules
          else if (rule.type === "exclude") {
            // We remove from the excluded values the ones that are not (yet) reachable
            rule.values = rule.values.filter(value => reachableValuesId.has(value))
            // If the rule excludes all the values, we can remove it
            // Note : at this step, the rule.values should have only one value, so this should always be the case
            if (!rule.values.length) {
              console.log(`The excluding rule ${rule.name} of ${axi.name} does not apply because none of the excluded values are reachable`);
              axi.rules.splice(i, 1);
              i--;
            } else {
              // If an exclude rule excludes a value that is only included by mutally exclusive rules above, we can remove it. 
              // For example, a rule that removes "Monogram" color only if "Material" is "Epi" is useless if the rules that includes "Monogram" are only applied when "Material" is something else than "Epi"
              let aboveRules = axi.rules.slice(0, i);
              if (!aboveRules.some(aboveRule => aboveRule.type === 'include' && aboveRule.values.some(val => rule.values.includes(val)) && !this.areMutuallyExclusive(rule, aboveRule))) {
                console.log(`The excluding rule ${rule.name} of ${axi.name} does not apply because the excluded value is not included by any rule above that could apply`);
                axi.rules.splice(i, 1);
                i--;
              }
            }
          }
        }
        // If the axi is computed, we consider "reachables" the values that are set by at least one rule
        else if (axi.type === "computed") {
          reachableValuesId.add(rule.value)
        }
      }
      // We keep only the values that are reachable ; it will help to minimize the number of rules of the next axis
      axi.values = axi.values.filter(value => reachableValuesId.has(value.id))
      // We create a dictionary of values by id that will allow a faster access to the values
      axi.valuesById = axi.values.reduce((acc, val) => {
        acc[val.id] = val
        return acc
      }, {})
    }
    return {
      // We keep the offer id and name
      id: offerCopy.id,
      name: offerCopy.name,
      // Instead of a list of configurators, we keep only the one that is relevant
      config: offerCopy.configs.find(conf => conf.id === config.id),
      // We return the optimized axis
      axis: configAxis
    }
  },

  // Example of index
  // index = /** @type {IndexOfRules} */ {
  //   axi: { ... }, // Reference to axi1
  //   rules: [ // Rules for axi1
  //     { order: 0, rule: axi1rule0ref },
  //     { order: 1, rule: axi1rule1ref },
  //     { order: 2, rule: axi1rule2ref },
  //     // ...
  //   ],
  //   constrained: {
  //     axi1value1id: {
  //       axi: { ... }, // Reference to axi2
  //       rules: [ // Rules for axi2 if axi1 is axi1value1id
  //         // ...
  //       ],
  //       constrained: {
  //         axi2value1id: {
  //           axi: { ... }, // Reference to axi3
  //           rules: [ // Rules for axi3 if axi1 is axi1value1id and axi2 is axi2value1id
  //             // ...
  //           ],
  //           constrained: {
  //             // ...
  //           },
  //           unconstrained: {
  //             // ...
  //           }
  //         },
  //         // ...
  //       },
  //       unconstrained: {
  //         axi: { ... }, // Reference to axi3
  //         rules: [ // Rules for axi3 if axi1 is axi1value1id and axi2 is not constrained
  //           // ...
  //         ],
  //         constrained: {
  //           // ...
  //         },
  //         unconstrained: {
  //           // ...
  //         }
  //       }
  //     }
  //   },
  //   unconstrained: {
  //     axi: { ... }, // Reference to axi2
  //     rules: [ // Rules for axi 2 if axi1 is not constrained
  //       // ...
  //     ],
  //     constrained: {
  //       // ...
  //     },
  //     unconstrained: {
  //       // ...
  //     }
  //   }
  // }

  /** 
   * Return an index of rules to quickly find the applicable rules for a given axi
   * @param {ConfigOffer} configOffer - The offer simplified for a given configurator
   * @param {Date} targetDate - If provided, the rules that are not applicable at this date are skipped
   * @returns {IndexOfRules} 
   * */
  getRulesIndex(configOffer, targetDate = new Date()) {
    console.time("Building Index");
    // We init our index
    let index = {};
    // We init an order index for the rules so we can reorganize them once deindexed
    let order = 0;
    // For each axi
    for (const axi of configOffer.axis) {
      // For each rule of each axi
      for (const rule of axi.rules) {
        // We skip the rule if it is not applicable based on the dates
        if (targetDate && ((rule.start && new Date(rule.start) > targetDate) || (rule.end && new Date(rule.end) < targetDate))) continue;
        // We store it in the index based on its constraints
        let curIndex = index;
        // We loop over the axis again, because the constraints apply to the axis
        for (const _axi of configOffer.axis) {
          // We keep a reference to which axi we are talking about at this depth of the index
          // Note: it is mostly useful for debugging and reading the index tree; it is not used in the logic
          if (!curIndex.axi) curIndex.axi = _axi;
          // If _axi === axi, there is no more constraint and we store the rule in the index
          if (_axi.id === axi.id) {
            if (!curIndex.rules) curIndex.rules = [];
            curIndex.rules.push({ order: order++, rule })
            break;
          } else {
            // We look if the rule has a constraint on the current _axi and get the value if any
            const condition = rule.constraints.find(constr => constr.axi.id === _axi.id)?.conditions[0];
            if (condition) {
              if (!curIndex.constrained) curIndex.constrained = {}
              if (!curIndex.constrained[condition]) curIndex.constrained[condition] = {}
              curIndex = curIndex.constrained[condition]
            } else {
              if (!curIndex.unconstrained) curIndex.unconstrained = {}
              curIndex = curIndex.unconstrained
            }
          }
        }
      }
    }
    console.timeEnd("Building Index");
    return index;
  },

  /**
   * Return the applicable rules for a given axi based on the current configuration
   * @param {Axi} axi 
   * @param {Configuration[]} currentConfiguration 
   * @param {Date} [targetDate=new Date()] - If provided, the rules that are not applicable at this date are skipped
   * @returns {Rule[]}
   */
  getApplicableRules(axi, currentConfiguration, targetDate = new Date()) {
    let rules = axi.rules || [];
    // We now do the filtering based on the start, end and constraints
    return rules.filter(rule => {
      // Check the start date
      if (rule.start && new Date(rule.start) > targetDate) return false
      // Check the end date
      if (rule.end && new Date(rule.end) < targetDate) return false
      // We keep only the rules that are compatible with the configuration
      const constraints = rule.constraints || []
      if (constraints.length && constraints.some(constraint => !constraint.conditions.includes(currentConfiguration.find(e => e.axi.id === constraint.axi.id)?.value?.id))) {
        // console.log(`The rule ${rule.name} of ${axi.name} does not apply because of the conditions`)
        return false
      }
      return true
    })
  },

  /** 
   * Return the applicable rules for a given axi based on the current configuration
   * @param {Axi} axi
   * @param {Configuration[]} currentConfiguration
   * @param {IndexOfRules} rulesIndex
   * @returns {Rule[]} 
   */
  getApplicableRulesWithIndex(axi, currentConfiguration, rulesIndex) {
    // We prepare an array to store the rules that apply to the axi based on the current configuration
    const rulesFromIndex = [];
    // We will "explore" all the relevant branches of the index, so we prepare a stack of indexes
    let indexesStack = [rulesIndex];
    // For each axi of the current configuration
    for (const confAxi of currentConfiguration) {
      // We prepare the new stack of indexes
      const newIndexesStack = [];
      // We get the value of the current configuration for this axi
      const valueId = confAxi.value?.id; // "Text" axi does not have a value
      // For each of the indexes in the stack
      for (const index of indexesStack) {
        // If there is a branch based on the value of the current configuration
        if (valueId && index?.constrained?.[valueId]) {
          // We add it to the new stack
          newIndexesStack.push(index.constrained[valueId])
        }
        // If there is a branch for unconstrained configurations
        if (index.unconstrained) {
          // We add it to the new stack
          newIndexesStack.push(index.unconstrained)
        }
      }
      // We update the stack of indexes
      indexesStack = newIndexesStack;
    }
    // We have now explored all the relevant branches of the index
    for (const index of indexesStack) {
      // For each branch that has a rule
      if (index.rules) {
        // We add the rule to the list of rules that apply to the axi
        rulesFromIndex.push(...index.rules)
      }
    }
    // We sort the rules based on their order property
    rulesFromIndex.sort((a, b) => a.order - b.order);
    // We keep only the rules (and not the order property)
    return rulesFromIndex.map(indexedRule => indexedRule.rule)
  },

  /**
   * Return the values available for an axi based on the applicable rules
   * @param {Axi} axi
   * @param {Rule[]} applicableRules
   * @returns {Value[]}
   */
  getValues(axi, applicableRules) {
    let valuesId = []; // array of values IDs
    if (axi.type === "choice") {
      // TODO : If no rule, we should raise an error instead of defaulting to all the values
      if (!applicableRules.length) {
        valuesId = axi.values.map(val => val.id);
      }
      for (let rule of applicableRules) {
        if (rule.type === "exclude") {
          if (rule.values.length) {
            // Remove the specified values from the valuesId
            valuesId = valuesId.filter(valueId => !rule.values.includes(valueId))
          } else {
            // Remove all the values of the axi
            valuesId = []
          }
        } else {
          if (rule.values.length) {
            // Add the specified values in addition to the previous one
            valuesId = [...new Set([...valuesId, ...rule.values])]
          } else {
            // Add all the values of the axi
            valuesId = axi.values.map(val => val.id)
          }
        }
      }
      return axi.values?.filter(val => valuesId.includes(val.id)) || [];
    }
    return [];
  },

  /**
   * Return the value of an axi based on the applicable rules
   * @param {Axi} axi
   * @param {Rule[]} applicableRules
   * @param {Configuration[]|undefined} currentConfiguration
   * @returns {Value}
   */
  getValue(axi, applicableRules, currentConfiguration) {
    const previousValue = currentConfiguration?.find(e => e.axi.id === axi.id)?.value
    if (axi.type === "choice") {
      const allowedValues = this.getValues(axi, applicableRules)
      return allowedValues.find(val => val.id === previousValue?.id) || allowedValues[0] // HERE WE CAN DECIDE TO ANOTHER DEFAULT
    } else {
      const lastElement = applicableRules[applicableRules.length - 1];
      if (axi.type === "computed") {
        if (!lastElement) {
          return axi.values[0] // TODO : If no rule, we should raise an error instead of defaulting to the first value
        } else {
          if (axi.valuesById?.[lastElement.value]) return axi.valuesById[lastElement.value];
          return axi.values?.find(val => val.id === lastElement.value) || null;
        }
      } else if (axi.type === "text") {
        return previousValue || null;
      }
    }
  },

  /**
   * Return the visibility of an axi based on the applicable rules
   * @param {Axi} axi
   * @param {Rule[]} applicableRules
   * @returns {Boolean}
   */
  getVisible(axi, applicableRules) {
    if (axi.type === "choice") {
      const allowedValues = this.getValues(axi, applicableRules)
      return allowedValues.length <= 1 ? false : true
    }
    else if (axi.type === "text") {
      const lastElement = applicableRules[applicableRules.length - 1];
      return lastElement?.visibility !== "hide"; // TODO : If no rule, we should raise an error instead of defaulting to visible
    }
    else {
      return false
    }
  },

  // HELPERS FOR RULES CONSTRAINTS

  // A rule is equally or less restrictive than another if...
  isEquallyOrLessRestrictive(/** @type {Rule} */ thisRule, /** @type {Rule} */ thanOtherRule) {
    // All its constrained axis are also constrained in the other rule, and have at least the same conditions
    const constraintEquallyOrLessRestrictive = thisRule.constraints.every(constr => {
      const otherConstr = thanOtherRule.constraints.find(_constr => _constr.axi.id === constr.axi.id)
      return otherConstr && otherConstr.conditions.every(cond => constr.conditions.includes(cond))
    })
    // And applies on an equal or wider time range
    const timerangeEquallyOrLessRestrictive =
      (!thisRule.start || (thanOtherRule.start && new Date(thisRule.start) <= new Date(thanOtherRule.start))) &&
      (!thisRule.end || (thanOtherRule.end && new Date(thisRule.end) >= new Date(thanOtherRule.end)))
    return constraintEquallyOrLessRestrictive && timerangeEquallyOrLessRestrictive
  },

  // Two rules are mutually exclusive if...
  areMutuallyExclusive(/** @type {Rule} */ rule1, /** @type {Rule} */ rule2) {
    // They are constrained on exactly the same axis, but with striclty different conditions
    const constraintsAreMutuallyExclusive = rule1.constraints.every(constr1 => {
      const constr2 = rule2.constraints.find(_constr => _constr.axi.id === constr1.axi.id)
      return constr2 && constr1.conditions.every(cond => !constr2.conditions.includes(cond))
    })
    // Or they apply on incompatible time ranges 
    const timeRangesAreMutuallyExclusive =
      (rule1.start && rule2.end && new Date(rule1.start) > new Date(rule2.end)) ||
      (rule2.start && rule1.end && new Date(rule2.start) > new Date(rule1.end));
    return constraintsAreMutuallyExclusive || timeRangesAreMutuallyExclusive
  },

  // Two rules are equally restrictive if...
  areEquallyRestrictive(/** @type {Rule} */ rule1, /** @type {Rule} */ rule2) {
    // They are equally or less restrictive than each other
    const rule1IsEquallyOrLessRestrictive = this.isEquallyOrLessRestrictive(rule1, rule2);
    const rule2IsEquallyOrLessRestrictive = this.isEquallyOrLessRestrictive(rule2, rule1);
    return rule1IsEquallyOrLessRestrictive && rule2IsEquallyOrLessRestrictive;
  },

  // OBJECTS GENERATOR

  createDefaultRuleChoiceType(/** @type {Axi} */ axi) {
    // By default, we include all the values
    return {
      id: this.createId(),
      name: `Default rule for ${axi.name}`,
      configs: [],
      conditions: [],
      type: "include",
      values: []
    }
  },

  createDefaultRuleComputedType(/** @type {Axi} */ axi) {
    // If the axi has only one value
    if (axi.values.length === 1) {
      // We return a rule that applies this value by default
      return {
        id: this.createId(),
        name: `Default rule for ${axi.name}`,
        configs: [],
        conditions: [],
        value: axi.values[0].id
      }
    }
    // Else, if there is no option or too many options, we do not return any default
    // That will make the generator raise an error so the user can fix the issue
    // by either creating an explicit rule or by keeping only one option 
  },

  createDefaultRuleTextType(/** @type {Axi} */ axi) {
    // By default, we show the text input
    return {
      id: this.createId(),
      name: `Default rule for ${axi.name}`,
      configs: [],
      conditions: [],
      visibility: "show"
    }
  },

  createDefaultRule(/** @type {Axi} */ axi) {
    if (axi.type === "choice") {
      return this.createDefaultRuleChoiceType(axi)
    } else if (axi.type === "computed") {
      return this.createDefaultRuleComputedType(axi)
    } else if (axi.type === "text") {
      return this.createDefaultRuleTextType(axi)
    }
  }
};

export default function useStore() {
  // Bind methods to the store
  const boundMethods = Object.keys(methods).reduce((acc, key) => {
    if (typeof methods[key] === 'function') {
      acc[key] = methods[key].bind(methods);
    } else {
      acc[key] = methods[key];
    }
    return acc;
  }, {});

  return { state, ...getters, ...boundMethods };
}
